app-edit-franchisee-user {
    .clicker-pointer {
      cursor: pointer;
    }
  
    form {
      .p-dropdown,
      input.p-inputtext {
        font-weight: 600 !important;
        height: 2rem;
        padding: 0 0.75rem;
        width: 100%;

        @media (min-width: 576px) {
          margin-left: 1rem;
        }
      }
  
      .p-dropdown .p-dropdown-label {
        line-height: 2rem;
        padding: 0;
      }
    }
    abp-advanced-entity-filters .card .card-body {
      padding-bottom: 0px;
    }
    abp-advanced-entity-filters .card 
    {
      margin-top:10px;
      margin-bottom:10px;
    }
    abp-page .content-header-title {
      margin-bottom:10px;
    }
    abp-page .card .card-body {
      padding:16px;
      padding-top: 10px;
    }
  }
  