// Lepton X Theme

:root .lpx-theme-dark {
  --lpx-logo: url('/assets/images/logo/honey-do-service-logo.png');
  --lpx-logo-icon: url('/assets/images/logo/ttb.png');
  --lpx-card-title-text-color: #2f6a34;
  --lpx-brand: black;
  --lpx-navbar-color: #2f6a34;
  --lpx-active-text-color: black;
  --lpx-navbar-active-text-color: black;
  --lpx-card-bg: white;
  --lpx-content-text: #325168;
  --lpx-border-color: #e8eef3;

  .ps .ps__rail-x:hover,
  .ps .ps__rail-y:hover,
  .ps .ps__rail-x:focus,
  .ps .ps__rail-y:focus,
  .ps .ps__rail-x.ps--clicking,
  .ps .ps__rail-y.ps--clicking {
    background-color: white;
  }

  .p-datepicker {
    .p-timepicker {
      display: none;
    }
  }

  .minutes {
    .p-dropdown {
      margin-right: 1rem;
    }

    .customMinutesInput.p-inputtext {
      margin-right: 1rem;
      max-width: 20%;
    }
  }

  .card {
    color: black;
    background-color: white;
    --bs-card-color: #325168;
  }

  .form-control {
    background-color: #f0f4f7;
    border-color: #e8eef3;
    color: #325168;
  }

  .modal-content {
    background-color: white;
    color: #325168;
  }

  .modal-header h3 {
    color: #161616;
  }
}

.lpx-theme-dim {
  --lpx-logo: url('/assets/images/logo/honey-do-service-logo.png');
  --lpx-logo-icon: url('/assets/images/logo/ttb.png');
  --lpx-card-title-text-color: #2f6a34;
  --lpx-brand: black;
  --lpx-navbar-color: #2f6a34;
  --lpx-active-text-color: black;
  --lpx-navbar-active-text-color: black;

  .ps .ps__rail-x:hover,
  .ps .ps__rail-y:hover,
  .ps .ps__rail-x:focus,
  .ps .ps__rail-y:focus,
  .ps .ps__rail-x.ps--clicking,
  .ps .ps__rail-y.ps--clicking {
    background-color: white;
  }

  .p-datepicker {
    .p-timepicker {
      display: none;
    }
  }

  .minutes {
    .p-dropdown {
      margin-right: 1rem;
    }

    .customMinutesInput.p-inputtext {
      margin-right: 1rem;
      max-width: 20%;
    }
  }
}

:root .lpx-theme-light {
  --lpx-logo: url('/assets/images/logo/honey-do-service-logo.png');
  --lpx-logo-icon: url('/assets/images/logo/ttb.png');
  --lpx-brand: black;
  --lpx-navbar-color: #2f6a34;
  --lpx-active-text-color: black;

  .ps .ps__rail-x:hover,
  .ps .ps__rail-y:hover,
  .ps .ps__rail-x:focus,
  .ps .ps__rail-y:focus,
  .ps .ps__rail-x.ps--clicking,
  .ps .ps__rail-y.ps--clicking {
    background-color: white;
  }

  .p-datepicker {
    .p-timepicker {
      display: none;
    }
  }

  .minutes {
    .p-dropdown {
      margin-right: 1rem;
    }

    .customMinutesInput.p-inputtext {
      margin-right: 1rem;
      max-width: 20%;
    }
  }
}

.lpx-content {
  min-height: calc(100vh - 107px);
}

.lpx-content-container {
  .lpx-content:not(:has(.app-closing-ratio-report)) {
    padding: 0.5em 1.5em 0 2em;
  }

  .lpx-content:has(.app-closing-ratio-report) {
    padding: 0;
  }
}

.lpx-context-menu-user-name {
  color: white;
}

.lpx-sidebar-container {
  position: relative;
  z-index: 0;
  min-height: 100%;
  height: initial;

  .lpx-sidebar {
    min-height: 100%;

    .lpx-nav {
      height: 100%;
    }
  }

  .lpx-logo-container {
    lpx-icon {
      display: none;
    }
  }
}

.lpx-context-menu-user-email {
  color: white;
}

#lpx-wrapper {
  display: flex;
  margin-bottom: 72px;
}

@media (min-width: 768px) {
  #lpx-wrapper {
    display: flex;
    min-height: 0;
    max-height: calc(100vh - 107px);
    overflow-y: auto;
    height: 100%;
    margin-bottom: 0;
  }
} 

#lpx-wrapper.full .lpx-content {
  background-color: #d0e3c7;
}

#lpx-wrapper.hover-trigger .lpx-sidebar-container:not(:hover) {
  max-width: none;
}

.lpx-content-wrapper {
  border: none;
}

.lpx-menu-filter {
  display: none;
}

.lpx-topbar-container .lpx-topbar {
  display: none;
}

.lpx-footbar-container .lpx-footbar {
  display: none;
}

.lpx-toolbar-container {
  display: none;
  min-height: 100%;
}

.lpx-content-container {
  background-color: #d0e3c7;
  margin: 0 !important;
  width: 100%;
  min-height: 100%;
}

.lpx-scroll-container {
  min-height: 0 !important;
  max-height: 100%;
  height: 100%;
}

.lpx-brand-logo {
  background-size: 243px;
  height: 117px;
}

.lpx-nav {
  padding-top: 0;
  background-color: #2f6a34;
  color: white;
}

.lpx-nav-menu .lpx-menu-item-link {
  color: white;
}

.lpx-logo-container {
  background-color: #2f6a34;
}

.lpx-sidebar .lpx-logo-container {
  display: none !important;
  background-color: #2f6a34;
  position: initial;

  .menu-collapse-icon {
    top: 31%;
  }
}

.lpx-sidebar-container {
  min-width: 219px;
}

.lpx-sidebar-container .lpx-sidebar .lpx-nav .lpx-nav-menu {
  max-width: 212px;
}

.lpx-nav-menu {
  margin-left: 1.6rem !important;
}

@media only screen and (min-width: 768px) and (max-width: 1199px) {
  .lpx-sidebar-container {
    min-width: 240px !important;
  }
}

.lpx-nav-menu .lpx-menu-item-link .lpx-menu-item-text {
  overflow: unset !important;
  text-overflow: unset !important;
}

.lpx-menu-filter .menu-filter-clear.hidden {
  display: none;
}

.lpx-toolbar-container {
  background-color: white;
}

.lpx-nav-menu {
  border-radius: 1rem;
  margin: 0.4rem;

  li {
    color: white;
  }

  .lpx-menu-item-link {
    transition: none;

    .lpx-menu-item-icon .lpx-icon {
      transition: none;
    }
  }

  .lpx-menu-item-link.selected {
    border-radius: 1rem;
    background-color: #d0e3c7;
  }

  .lpx-menu-item-link .lpx-menu-item-text {
    font-weight: 600;
  }

  .lpx-menu-item-link:hover {
    text-decoration: underline;
    background-color: #d0e3c7;
    border-radius: 1rem;
  }

  .lpx-inner-menu-item .lpx-menu-item-link:hover {
    background-color: #d0e3c7;
  }

  .lpx-inner-menu-item .lpx-menu-item-link {
    padding-left: 50px;
    transition: none;
  }

  .lpx-inner-menu-item .lpx-menu-item-link.selected {
    background-color: #d0e3c7;
  }
}

.hover-trigger .lpx-sidebar-container:not(:hover) .lpx-logo-container {
  padding-left: 8px;
  padding-right: 5px;
  width: 78px;
}

.hover-trigger .lpx-sidebar-container:not(:hover) .lpx-brand-logo {
  background-size: 69px;
}

.hover-trigger .lpx-menu-filter {
  display: none;
}

.lpx-toolbar .lpx-nav-menu .lpx-menu-item-link:hover {
  background-color: white;
}

.group-menu-item {
  padding-left: 0.5rem;
  margin: 0.5rem 0;
  font-weight: 700;
  font-size: 1rem;
}

.lpx-mobile-nav-tab {
  height: 62px;

  a i {
    color: #d0e3c7;
  }

  a:hover {
    color: #2f6a34;
  }

  .mobile-item-text {
    margin-top: 0;
  }
}

.lpx-mobile-nav-tab.selected {
  border-radius: 1rem;
  background-color: #d0e3c7;

  i {
    color: #2f6a34;
  }
}

@media only screen and (min-width: 1200px) {
  #lpx-wrapper.hover-trigger .lpx-content-container {
    margin-left: 84px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 1199px) {
  .lpx-sidebar-container:hover {
    min-width: 280px;
  }

  .lpx-sidebar-container:not(:hover) {
    .lpx-logo-container {
      padding-left: 8px;
      padding-right: 5px;
      width: 78px;
    }

    .lpx-brand-logo {
      background-image: var(--lpx-logo);
    }

    .group-menu-item.hidden-in-hover-trigger {
      display: block;
      margin: 0.5rem 0;
    }

    .lpx-menu-item-text.hidden-in-hover-trigger {
      display: initial;
    }

    .dd-icon.hidden-in-hover-trigger {
      display: initial;
    }
  }

  .lpx-menu-filter {
    display: none;
  }

  .lpx-content-container {
    margin-left: 280px;
  }

  .lpx-sidebar-container {
    min-width: 280px;

    .lpx-sidebar {
      .lpx-nav {
        min-width: 280px;

        .lpx-logo-container {
          width: 280px;
          padding: 20px;
        }
      }
    }
  }
}
