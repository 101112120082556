app-processing-fee {
  input::-webkit-outer-spin-button,
  input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }

  /* Firefox */
  input[type=number] {
    -moz-appearance: textfield;
  }
  .clicker-pointer {
    cursor: pointer;
  }
  
  form {
    .p-inputtext {
      font-weight: 600 !important;
      height: 2rem;
      padding: 0 0.75rem;
      width: 100%;

      @media (min-width: 576px) {
        margin-left: 1rem;
        width: auto;
      }
    }

    .p-dropdown .p-dropdown-label {
      line-height: 2rem;
    }
  }

  abp-advanced-entity-filters .card .card-body {
    padding-bottom: 0px;
    border-radius: 0px;

  }
  abp-advanced-entity-filters .card 
  {
    margin-top:10px;
    margin-bottom:10px;
  }

  abp-page .content-header-title {
    margin-bottom:10px;
  }

  abp-page .card  {
    border: 0.5px solid darkgray;
    margin-bottom:0px;
    margin-top: 10px;
    border-radius: 0px;
  }
}