/* You can add global styles to this file, and also import other style files */
@import 'scss/site';
@import 'scss/master';
@import '../node_modules/angular-calendar/css/angular-calendar.css';

body {
  background-color: #d0e3c7;
}

.p-checkbox .p-checkbox-box.p-highlight {
  border-color: #2f6a34 !important;
  background: #2f6a34 !important;
}
.p-checkbox:not(.p-checkbox-disabled) .p-checkbox-box:hover {
  border-color: #2f6a34 !important;
}
.p-checkbox:not(.p-checkbox-disabled) .p-checkbox-box.p-focus {
  outline: 0 none;
  outline-offset: 0;
  box-shadow: 0 0 0 0.2rem #bfdbfe;
  border-color: #2f6a34 !important;
}
.p-checkbox:not(.p-checkbox-disabled) .p-checkbox-box.p-highlight:hover {
  border-color: #2f6a34 !important;
  background: #2f6a34 !important;
  color: #ffffff;
}

::placeholder {
  color: lightgrey;
  opacity: 1; /* Firefox */
}

::-ms-input-placeholder { /* Edge 12 -18 */
  color: lightgrey;
}

.lpx-content-wrapper {
  position: relative !important;
  height: 100%;
}

@media (min-width: 768px) {
  #lpx-wrapper {
    min-height: calc(100vh - 35px) !important;
  }
}