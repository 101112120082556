app-customer {

  .clicker-pointer {
    cursor: pointer;
  }

  form {
    .p-dropdown,
    input.p-inputtext:not(.p-inputtextarea) {
      font-weight: 600;
      padding: 0 0.75rem;
      height: 2rem;
      width: 100%;

      @media (min-width: 576px) {
        margin-left: 1rem;
      }
    }

    .p-inputtextarea {
      font-weight: 600 !important;
      padding: 0 0.75rem;
      width: 100%;

      @media (min-width: 576px) {
        margin-left: 1rem;
      }
    }

    .p-dropdown .p-dropdown-label {
      line-height: 2rem;
      padding: 0;
    }
  }

  abp-page .content-header-title {
    margin-bottom: 10px;
  }

  abp-page .card {
    border: 0.5px solid darkgray;
    margin-bottom: 0px;
    margin-top: 10px;
    border-radius: 0px;
  }

  .custom-button {
    border-radius: 0;
  }

  abp-advanced-entity-filters .card {
    border: none;
    margin-top: 10px;
    width: 100%;
  }

  .p-datatable .p-datatable-tbody>tr.selected {
    background-color: yellowgreen;
    color: black;
  }

  /* Chrome, Safari, Edge, Opera */
  input::-webkit-outer-spin-button,
  input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }

}