app-edit-client-source {

  .p-inputtext {
    width: 100%;

    @media (min-width: 576px) {
      margin-left: 1rem;
      max-width: 20rem;
    }
  }

  .btn-primary[disabled] {
    background-color: #4c8749;
    border-color: #4c8749; 
  }
}
