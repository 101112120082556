@page
{
    margin-right:0cm;
}

@media print{
    .overflow-container{
    overflow:hidden
    }
    ::-webkit-scrollbar {
        display: none;
    }
    
}

app-job-details{
    z-index: 9999;
    top:0;
    left:0;
    background-color: white;
    width:100%;
    height:100%;
    position: absolute;
    overflow: auto;

    .table{
        border:1;
        background-color: white;
        border-color: black;
        border-spacing:3px;
    }

  }
  