app-edit-customer {
  .clicker-pointer {
    cursor: pointer;
  }

  .p-datatable.p-datatable-striped .p-datatable-tbody > tr:nth-child(even) {
    background: lavender;
  }

  input[readonly] {
    pointer-events: none;
    background-color: #f2f2f2;
    color: #444444;
  }

  .btn-primary[disabled] {
    background-color: #4c8749;
    border-color: #4c8749;
  }

  .job-visits-form {
    p-inputNumber {
      .p-inputtext:not(.p-inputtextarea) {
        width: 6rem;
      }
    }
  }
}
