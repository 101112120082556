$error-color: #dc3545;

app-http-errors {
  .alert.alert-error {
    margin: 1rem;
    color: $error-color;
    background-color: tint($error-color, 90%);
    box-shadow: none !important;
    border: 1px $error-color solid;
    font-weight: bolder;

    .p-button.p-button-icon-only.p-button-rounded {
      height: 2rem !important;
      width: 2rem;
    }
  }
}
