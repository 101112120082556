.btn-primary {
  background-color: #2f6a34;
  border-color: #2f6a34;
}

.p-datatable table { border-collapse: separate; }

.btn-outline-primary {
  border-color: #2f6a34;
  color: #2f6a34;
}

#abp-http-error-container {
	display: none;
}

.btn-secondary {
  background-color: white;
  border-color: #2f6a34;
  color: #2f6a34;
}

.btn-secondary:hover {
  background-color: #2f6a34;
  border-color: #2f6a34;
  color: white;
}

//Primeng Confirm Dialog
.p-dialog {
  width: 35rem;

  .p-dialog-footer {
    display: flex;
    justify-content: space-between;

    button {
      width: 48%;
    }

    .p-button {
      padding: 0.5rem 1.25rem;
    }

    .p-confirm-dialog-accept {
      margin-right: 0;
      background-color: #2f6a34;
      border-color: #2f6a34;
    }

    .p-confirm-dialog-accept:hover {
      background-color: #2d4fd9;
      border-color: #2a4acc;
      color: white;
    }

    .p-confirm-dialog-reject {
      background-color: white;
      border-color: #2f6a34;
      color: #2f6a34;
    }

    .p-confirm-dialog-reject:hover {
      background-color: #2d4fd9;
      border-color: #2a4acc;
      color: white;
    }

    button .p-button-label {
      font-weight: 400;
    }
  }
}

.p-dialog.p-confirm-dialog .p-confirm-dialog-message {
  margin-left: 0;
  font-weight: 600;
}

abp-confirmation {
  display: none;
}
