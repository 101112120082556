// LeptonX - Site Theme
@import 'components/leptonx-theme';

//Functions
@import 'functions';

// Header
@import 'components/ttb-header';

// Customer Center Page
@import 'components/customer';
@import 'components/edit-customer';
@import 'components/edit-job';
@import 'components/job-details';
//Franchisees Page
@import 'components/edit-franchise';
@import 'components/franchise';

//Franchisee Users Page
@import 'components/franchisee-user';
@import 'components/edit-franchisee-user';
@import 'components/franchise-item';

//Labor Rates Page
@import 'components/labor-rate';
@import 'components/labor-rate-item';
@import 'components/edit-labor-rate';

//Sales Tax Rate Page
@import 'components/sales-tax-rate-item';
@import 'components/sales-tax-rate';
@import 'components/edit-sales-tax-rate';

//Proccessing Fee Page
@import 'components/processing-fee-item';
@import 'components/processing-fee';
@import 'components/edit-processing-fee';

//Client Sources Page
@import 'components/client-sources';
@import 'components/edit-client-sources';

//Closing Ratio Report
@import 'components/closing-ratio-report';
@import 'components/print-preview';

//Calendar Page
@import 'components/calendar';

// Error Messages
@import 'components/error-messages';

.lpx-content-container {
    border-radius: 0 !important;
}
.lpx-sidebar-container .ps__rail-x { display: none !important; }

.outer-menu-item {
    margin-right: -1rem;
}