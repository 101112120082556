app-calendar {
  .cal-month-view {
    .cal-day-cell.cal-in-month.cal-has-events {
      cursor: default;
    }

    .cal-day-cell.cal-out-month {
      .cal-day-number,
      .cal-day-note {
        opacity: 0.5;
      }
    }
    .cal-day-cell {
      min-height: 100px;
      max-height: 200px;
    }

    #dayCell {
      display: flex;
      flex-direction: column;
      min-height: 100px;
      max-height: 200px;
      flex-grow: 1;
    }

    .cal-events {
      flex-direction: column;
      align-items: flex-start;
      overflow-y: auto;
      flex-wrap: nowrap;
      margin: 0;

      &::-webkit-scrollbar {
        width: 4px;
      }

      &::-webkit-scrollbar-track {
        background: #f0f0f0;
      }

      &::-webkit-scrollbar-thumb {
        background-color: #888;
        border-radius: 9px;
      }

      .cal-event {
        height: initial;
        border-radius: initial;
        background-color: initial;
        font-size: 0.8rem;
        padding: 5px;
        line-height: 1.3;
        margin: 0;
        width: 100%;

        &:hover {
          background-color: #d0e3c7;
          border-radius: 5px;
        }

        .event-description {
          display: flex;
          color: black;

          .start-of-job {
            color: green;
            font-weight: bolder;
            margin-right: 2px;
          }

          .end-of-job {
            color: red;
            font-weight: bolder;
            margin-left: 2px;
          }

          .event-job-visit.clickable {
            :hover {
              cursor: pointer;
            }
          }

          .event-job-sales-visit {
            color: #3a7ae9;

            &.clickable {
              :hover {
                cursor: pointer;
              }
            }
          }

          .craftsman {
            border-bottom: 1px solid gray;

            .highlight {
              background-color: #ffff00;

              .craftsman-name {
                font-weight: bold;
              }
            }

            .badge {
              border: 1px solid black;
              padding: 0.12rem 0.24rem;
            }
          }

          .estimator {
            border-bottom: 1px solid #3a7ae9;
          }
        }

        &.cal-draggable:hover {
          background-color: #d0e3c7;
          border-radius: 5px;
        }

        .cal-event-color {
          margin-top: 3px;
          width: 10px;
          border-radius: 50%;
          height: 10px;
          margin-right: 3px;
          flex-shrink: 0;
        }
      }
    }

    .cal-day-note {
      font-size: 0.8rem;
      padding: 0 5px;
      font-weight: 600;
      background-color: #f0f0f0;

      .clickable:hover {
        cursor: pointer;
        text-decoration: underline;
      }
    }

    .cal-cell-top {
      min-height: initial;
      -webkit-box-flex: initial;
      -ms-flex: initial;
      flex: initial;
      display: flex;
      justify-content: space-between;
      padding: 0 0.5rem;
      margin-bottom: 5px;
    }

    .cal-day-badge {
      margin-top: 5px;
      margin-left: 5px;
      border: 1px solid black;
      border-radius: 100%;

      &.production-badge {
        // background-color: black;
        background-color: transparent;
        color: black;
        border-color: black;
      }

      &.sales-badge {
        // background-color: #3a7ae9;
        background-color: transparent;
        color: #3a7ae9;
        border-color: #3a7ae9;
      }
    }

    .cal-day-cell.cal-today {
      background-color: #bce8f14c;
    }

    .cal-day-cell.cal-today .cal-day-number,
    .cal-day-number {
      font-size: 1rem;
      margin-top: 0;
      margin-right: 5px;
      margin-bottom: 0;
      opacity: 1;
      float: initial;

      &.non-clickable {
        text-decoration: none;
        &:hover {
          cursor: default;
        }
      }
    }
  }

  .p-inputswitch .p-inputswitch-slider {
    background-color: gray;
  }

  .p-inputtext:not(.p-inputtextarea) {
    font-weight: 600;
    padding: 0 0.75rem;
    height: 2rem;
  }

  .p-inputtextarea {
    font-weight: 600 !important;
    padding: 0 0.75rem;
  }

  .p-dropdown {
    width: 10rem;
    .p-dropdown-label {
      line-height: 2rem;
    }
  }
}
