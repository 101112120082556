@page {
    margin-right: 0cm;
}

@media print {
    .overflow-container {
        overflow: hidden
    }

    ::-webkit-scrollbar {
        display: none;
    }

}

app-closing-ratio-report {
    input::-webkit-outer-spin-button,
    input::-webkit-inner-spin-button {
      -webkit-appearance: none;
      margin: 0;
    }
    
    /* Firefox */
    input[type=number] {
      -moz-appearance: textfield;
    }

    // z-index: 9999;
    top: 0px;
    background-color: white;
    width: 100%;
    height: 100%;
    position: absolute;
    overflow: auto;

    .table {
        border: 1;
        background-color: white;
        border-color: black;
        border-spacing: 3px;
    }

    .clicker-pointer {
        cursor: pointer;
        color: #337ab7
    }

    .panel {
        padding: 15px;
        border-width: 1px;
        border-style: solid;
        margin-bottom: 5px;
        border-color: black;
    }

    .title 
    {
        font-weight: bold;
        color: black;
    }

    .text-black
    {
        color:black
    }

    .search-label
    {
        margin-right:3rem;
    }

}