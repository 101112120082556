app-ttb-header {
  .ttb-header-top {
    background-color: #1d4220;
    color: #fff;
  }

  .ttb-header-bottom {
    background-color: #2f6a34;
    color: #fff;
  }

  .top-bar-logout-button {
    background-color: #2f6a34;
    display: inline-block;
    border: solid 1px black;
    width: 80px;
    margin-left: 20px;
    margin-right: 20px;
    text-align: center;
    text-decoration: none;
    color: white;
    cursor: pointer;
  }

  .top-bar-button {
    min-width: fit-content;
    justify-content: end;
    color: white;
    background-color: #1d4220;
    text-align: center;
    border-radius: 5px;
    border-style: solid;
    border-width: 1px;
    border-color: black;
    overflow-x: hidden;
    cursor: pointer;
    text-decoration: none;
    padding-left: 15px;
    padding-right: 15px;
  }
}
