app-edit-franchise {

  input::-webkit-outer-spin-button,
  input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }

  /* Firefox */
  input[type=number] {
    -moz-appearance: textfield;
  }

  .clicker-pointer {
    cursor: pointer;
  }
  
  form {
    .p-inputtext {
      font-weight: 600;
      height: 2rem;
    }

    .p-dropdown,
    input.p-inputtext {
      margin-left: 0;
      
      @media (min-width: 576px) {
        margin-left: 1.5rem;
      }
    }

    p-inputmask,
    input.p-inputtext {
      width: 100%;
      
      @media (min-width: 576px) {
        width: auto;
      }
    }

    #franchise-emailAddress {
      width: 100%;

      @media (min-width: 576px) {
        width: 25rem;
      }
    }

    .p-dropdown .p-dropdown-label {
      line-height: 2rem;
    }
  }
    
  input[readonly]
  {
      pointer-events: none;
      background-color: #f2f2f2;
      color: #444444;
  }
  
  .btn-primary[disabled]
  {
    background-color: #4c8749;
    border-color: #4c8749; 
  }
}