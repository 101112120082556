app-edit-labor-rate {
  input[readonly] {
    pointer-events: none;
    background-color: #f2f2f2;
    color: #444444;
  }

  .p-inputtext {
    width: 100%;

    @media (min-width: 576px) {
      margin-left: 1rem;
    }
  }

  .btn-primary[disabled] {
    background-color: #4c8749;
    border-color: #4c8749; 
  }
  
}