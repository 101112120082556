app-edit-processing-fee
{
    
      input[readonly]
    {
        pointer-events: none;
        background-color: #f2f2f2;
        color: #444444;
    }
    
    .btn-primary[disabled]
    {
      background-color: #4c8749;
      border-color: #4c8749; 
    }
  
}